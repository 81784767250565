@font-face {
    font-family: 'Roboto';
    src: url('./static/Fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}

html,
body,
#root {
    height: 100%;
    font-family: 'Roboto', sans-serif;
}

.main-page {
    background-image: url('./static/Img/lift2.jpg') !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

.lift__shadow {
    box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
}

.lift__slider_icon {
    position : absolute;
    top      : 44%;
    font-size: 23px;
    transform: translate(0, -44%);
    width    : 20px;
    height   : 20px;
    cursor   : pointer;
    outline  : none;
    border   : none;
    color    : #B7B7B7;
}

.lift__slider_icon.left {
    left: -17px;
}

.lift__slider_icon.right {
    right: -17px;
}

.lift__star {
    color    : #FFC833 !important;
    font-size: 15px;
}

/* wrapper */

.wrapper {
    min-height    : 100vh;
    display       : flex;
    flex-direction: column;
}

.App-header {
    position: fixed;
    left    : 0;
    right   : 0;
    z-index : 10;
}

.content {
    height     : calc(100% - 65px);
    flex       : 1;
    display    : flex;
    align-items: stretch;
    padding    : 65px 25px 0 20px;
}

.content:not(.no_sidebar) {
    padding: 65px 25px 0 243px;
}

.content>div {
    width: 100%
}

.lift__header_logo_container {
    display    : flex;
    align-items: center;
    margin-left: 10px
}

.lift__header_navbar_container {
    display    : flex;
    align-items: center;
}

.lift__header_logo {
    width   : 43px;
    height  : 30px;
    /* width: 200px;
    height  : 33px; */
}

.lift__header_logo_text {
    margin-left: 15px;
    font-size  : 22px;
    line-height: 24px;
    font-weight: 500;
}

.lift__navbar_link {
    margin-left: 8px;
    font-weight: 300;
    font-size  : 16px;
    line-height: 19px;
}

.lift__navbar_link.line {
    margin: 0 25px
}

.lift__navbar_link:last-child {
    margin-right: 20px
}

.footer {
    position: relative;
}

.lift__footer_logo_item {
    text-align: center;
    max-width : 150px;
    position  : absolute;
}

.lift__footer_logo {
    width  : 38px;
    height : 38px;
    display: inline-block
}

.lift__footer_logo_item p {
    margin-top : 3px;
    font-size  : 9px;
    line-height: 7px;
}

.lift__footer_center {
    font-size      : 12px;
    text-align     : center;
    height         : 100%;
    display        : flex;
    justify-content: center;
    align-items    : center;
    font-weight    : 700;

}

.lift__auth_container {
    width: 100%
}

/* Auth */
.lift__auth_header {
    font-weight: 400;
    font-size  : 18px
}

.lift__auth_box {
    width: 360px
}

.lift__register_box {
    width: 360px
}

/* Sidebar */
.lift___sidebar_user_container {
    display        : flex;
    justify-content: center;
    align-items    : center;
}

.lift__sidebar_user_avatar {}

.lift__sidebar_user_name {
    font-size: 15px;
}

.lift__sidebar_user_name .lastname {
    text-transform: lowercase;
}

.lift__sidebar_user_name .lastname::first-letter {
    text-transform: uppercase;
}

.lift__sidebar_user_name .firstname,
.lift__sidebar_user_name .middlename {
    text-transform: uppercase;
}

.lift__sidebar_user_place {
    font-size: 13px;
    color    : #5f5f5f
}

.lift__menu {
    margin-top: 25px;
    font-size : 15px;
}

.lift__menu_item_link {
    padding    : 5px 9px;
    display    : flex;
    align-items: center;
    cursor     : pointer
}

.lift__menu_item_icon {
    position    : relative;
    margin-right: 7px;
}

.lift__menu_sub_item {
    padding    : 0 0 0 30px;
    height     : 34px;
    display    : flex;
    align-items: center;
    background : #F4F6F8;
    font-size  : 95%
}

/* equipment */

.lift__eq_filter_icon {
    font-size: 20px
}

.lift__eq_top {
    display: flex;
}

.lift__filter_item {
    flex-grow : 1;
    flex-basis: 0;
}

.lift__filter_item>button,
.lift__filter_item select,
.lift__filter_item input {
    font-size: 14px;
    height   : 38px;
}

.lift__eq_table {
    font-size: 14px;
}

.lift__eq_table tr {
    cursor: pointer;
}

/* pagination */
.lift__pagination {
    display        : flex;
    justify-content: center;
    align-items    : center;
    padding        : 6px 10px;
}

.lift__pagination_item {
    border-radius  : 2px;
    width          : 28px;
    height         : 28px;
    display        : flex;
    align-items    : center;
    justify-content: center;
    border         : 1px solid #D9D9D9;
    background     : #FFFFFF;
    margin-right   : 10px;
    cursor         : pointer;
    transition     : .2s
}

.lift__pagination_item:hover {
    background-color: #f2f2f2;
}


/* Passport */
.lift__passport_header_text {
    text-transform: uppercase;
    font-size     : 16px
}

.lift__passport_part_header {
    font-weight: 700;
    font-size  : 16px
}

.lift__passport_table tr>td:first-child {
    font-weight: 700;
}

.lift__element {
    display: none;
}

.lift__element.active {
    display: block;
}

.lift__stepper_items {
    width: 100%
}

/* profile */

.lift__profile_container {
    font-size: 13px;
}

.lift_profile__right_side_box {
    width          : 100%;
    background     : #fff;
    padding        : 25px 15px 20px;
    display        : flex;
    justify-content: center;
    align-items    : center;
    height         : 100%;
}

.lift_profile__left_slider {
    background: #fff;
}

.lift__profile_slider_item {
    width  : 100%;
    height : 130px;
    padding: 0 7px;
}

.lift__profile_slider_item>img {
    width     : 100%;
    height    : 100%;
    object-fit: cover;
}

.lift__profile__right_info_count {
    font-style    : normal;
    font-weight   : bold;
    font-size     : 36px;
    line-height   : 24px;
    text-align    : center;
    letter-spacing: -0.011em;
    color         : #1571AF;
    position      : relative;
}

.lift__profile__right_info_count span {
    font-size  : 13px;
    line-height: 24px;
    color      : #333333;
    font-weight: 300;
    position   : absolute;
    left       : 99%;
    width      : 50px;
    bottom     : -5px;
}

.lift__profile__right_info_text {
    font-size: 13px;
    color    : #333333;
}

.lift__profile__left_tabs {
    height       : 65px;
    padding      : 16px;
    border       : 1px solid #C4C4C4;
    border-radius: 23px;
    display      : flex;
    align-items  : center;
}

.lift__profile__left_tab_button {
    font-size    : 13px;
    font-weight  : 500;
    padding      : 2px 15px;
    border-radius: 23px;
    margin       : 0 40px 0 0;
    color        : #B7B7B7;
}

.lift__profile__left_tab_button.active {
    background-color: #fff;
    border          : 1px solid #C4C4C4;
    color           : #333;
}

.lift__profile_left_container {
    background: #F7F6F6;
    padding   : 10px;
}

.lift__profile_left_block {
    border-radius: 23px;
    background   : #fff;
    padding      : 15px;
    position     : relative;
}

.lift__profile_left_block.bottom {
    padding: 24px 25px;
}

.lift__profile_left_info_container {
    display: flex;
    width  : 100%;
}

.lift__profile_left_info {
    margin-right: 20px;
    min-width   : 15%;
}

.lift__profile_left_info_header {
    color    : #D9D9D9;
    font-size: 13px;
}

.lift__profile_left_info_text {
    font-size: 13px;
}

.lift__profile_left_simple_header {
    font-weight: 600;
    font-size  : 13px;
    margin     : 0 0 7px;
}

.lift__profile_left_simple_info>ul {
    list-style: decimal;
    padding   : 0 0 0 22px;
    font-size : 13px;
}

.lift__profile_left_simple_table {
    display    : flex;
    align-items: center;
}

.lift__profile_left_simple_table_header {
    color: #B7B7B7;
    width: 30%;
}

.lift__profile_left_simple_table_text {
    width      : 70%;
    line-height: 1rem;
}

.lift__profile_left_simple_table_place {
    font-size  : 14px;
    font-weight: 700;
}

.lift__profile_left_simple_table_block {
    background   : #D9D9D9;
    border-radius: 11px;
    text-align   : center;
    padding      : 5px 17px;
    display      : inline;
    margin       : 0 8px 0 0;
}

.lift__profile_left_item {
    background   : rgba(217, 217, 217, 0.29);
    border-radius: 11px;
    padding      : 14px 25px;
    display      : flex;
    align-items  : center;
    margin       : 0 0 8px;
}

.lift__profile_left_item_index {
    width: 5%;
}

.lift__profile_left_item_img {
    width     : 20%;
    text-align: center;
    position  : relative;
}

.lift__profile_left_item_img>div {
    width    : 70px;
    height   : 40px;
    position : absolute;
    top      : 54%;
    left     : 50%;
    transform: translate(-50%, -48%);
}

.lift__profile_left_item_img img {
    width     : 100%;
    height    : 100%;
    display   : inline;
    object-fit: contain;


}

.lift__profile_left_item_name {
    width: 35%;
}

.lift__profile_left_item_count {
    width    : 35%;
    color    : #C4C4C4;
    font-size: 12px;
}

.lift_profile__right_side_info {
    width     : 100%;
    background: #fff;
    padding   : 17px;
    height    : 100%;
}

.lift_profile__right_side_info_header {
    color     : rgba(97, 96, 96, 0.71);
    text-align: center;
}

.lift_profile__right_side_info_item {
    display       : flex;
    align-items   : center;
    flex-direction: column;
    text-align    : center;
}

.lift_profile__right_side_info_item img {
    width : 80px;
    height: 80px;
}

.lift__profile_edit {
    position  : absolute;
    top       : 10px;
    right     : 15px;
    color     : #616060;
    font-size : 16px;
    cursor    : pointer;
    transition: .1s;
}

.lift__profile_edit:hover {
    opacity: .8;
}

.lift__profile_edit.plus {
    top  : 12px;
    right: 17px;
}


/* Profile Edit */

.profile__edit_tabs, .passport__edit_tabs, .passport_acts__edit_tabs {
    width          : 100%;
    border-radius  : 23px;
    padding        : 13px 27px;
    border         : 1px solid #C4C4C4;
    display        : flex;
    justify-content: space-between;
}

.profile__edit_tab, .passport__edit_tab, .passport_acts__edit_tab {
    border-radius: 23px;
    padding      : 5px 12px;
    color        : #B7B7B7;
    transition   : .2s;
    border       : 1px solid transparent;
    font-size    : 14px;
}

.profile__edit_tab.active,
.profile__edit_tab:hover,
.passport__edit_tab.active,
.passport__edit_tab:hover,
.passport_acts__edit_tab.active,
.passport_acts__edit_tab:hover {
    border          : 1px solid #C4C4C4;
    background-color: #fff;
    color           : #333;
}

.lift__profile_edit_avatar {
    text-align     : center;
    padding        : 25px 0;
    display        : flex;
    justify-content: center;
}

.lift__profile_edit_avatar_img {
    position: relative;
    width   : 90px;
}

.lift__profile_edit_avatar img {
    border-radius: 50%;
    width        : 100%;
}

.lift__profile_edit_avatar_plus {
    position: absolute;
    right   : 0;
    bottom  : 0;
    cursor  : pointer;
}

.lift__profile_edit_avatar_plus svg {
    width : 22px;
    height: 22px;
}

.lift__profile_edit_formik .chakra-form-control>label {
    color    : #C4C4C4;
    font-size: 14px;
}

.lift__profile_edit_add_items {
    display       : flex;
    align-items   : center;
    text-align    : center;
    flex-direction: column;
    padding       : 25px 0;
    cursor        : pointer;
}

.lift__profile_edit_add_items svg {
    width : 26px;
    height: 26px;
}

.lift__profile_edit_add_items p {
    font-size : 12px;
    color     : #B7B7B7;
    margin-top: 2px;
}

.lift__doc_item {
    margin  : 0 0 15px;
    position: relative;
}

.lift__doc_item_header {
    color    : #C4C4C4;
    font-size: 13px;
}

.lift__doc_item_data {
    display    : flex;
    align-items: center;
    margin     : 8px 0 0;
}

.lift__doc_item_data img {
    width  : 34px;
    opacity: .3;
    height : 33px;
}

.lift__doc_item_data .lift__doc_item_data_text {
    margin-left: 13px;
    font-size  : 13px;
    color      : #C4C4C4;
}

.lift__doc_item_data svg {
    width : 16px;
    height: 16px;
}

.lift__doc_item_data_plus {
    position: absolute;
    left    : 24px;
    bottom  : 0;
    cursor  : pointer;
}

/* profile staff */
.lift__profile_staff {
    height    : 100%;
    background: #F7F6F6;
}

.lift__profile_staff_info {
    padding    : 20px 30px;
    display    : flex;
    align-items: center;
    position   : relative;
}

.lift__profile_staff_info_header {}

.lift__profil_staff_table {
    font-size: 13px;
}

.lift__profil_staff_table th {
    color         : #C4C4C4;
    font-size     : 13px;
    font-weight   : 400;
    text-transform: initial;
}

.lift__profil_staff_table tr:not(.first) {
    background: #fff;
    cursor    : pointer;
    transition: .1s;
}

.lift__profil_staff_table tr:not(.first):hover {
    background: rgba(255, 255, 255, 0.2);
}

.lift__profile_staff_info_filter {
    font-size: 25px;
    position : absolute;
    right    : 5px;
    cursor   : pointer;
}

.lift__profile_staff_info_filter.change_position {
    right: 30px;
}

.lift__profile_staff_info_add {
    font-size: 25px;
    position : absolute;
    right    : 5px;
    cursor   : pointer;
}

.lift__profile_staff_info_add.plus {
    position  : absolute;
    right     : 5px;
    top       : 28px;
    color     : #616060;
    font-size : 16px;
    cursor    : pointer;
    transition: .1s;
}

.lift__profile_staff_info_add.plus:hover {
    opacity: .7;
}

.lift__staff_busy_td {
    position: relative;
}

.lift__staff_busy {
    color    : #EC2727 !important;
    font-size: 26px;
    position : absolute;
    top      : 50%;
    transform: translate(0, -50%);
}

.lift__staff_busy.build {
    left     : 43px;
    font-size: 12px;
    top      : 40%;
}

.lift__staff_fio {
    display    : flex;
    align-items: center;
    position   : relative;
}

.lift__staff_fio p {
    padding-left: 20px;
}

.lift__staff_fio img {
    width        : 28px;
    margin-right : 10px;
    border-radius: 50%;
    position     : absolute;
    left         : 0
}


.lift__staff_busy.success {
    color: #12CE0E !important;
}

.lift__profile_edit_doc_header {
    color: #B7B7B7;
}

.lift__profile_edit_doc_right {
    border-left: 1px solid #EFEFEF;
    padding    : 0 0 0 30px;
}

.lift__appointment_edit_items {}

.lift__appointment_edit_item {
    background   : rgba(247, 246, 246, 0.86);
    padding      : 12px 13px;
    display      : flex;
    align-items  : center;
    border-radius: 11px;
    margin-bottom: 7px;
    font-size    : 13px;
}

.lift__appointment_edit_item_idx {
    width: 10%;
}

.lift__appointment_edit_item_name {
    width: 45%;
}

.lift__staff_edit {
    transition: .1s;
}

.lift__staff_edit:hover {
    opacity: .7;
}

.lift__appointment_edit_item_level {
    width: 45%;
}

.lift__review {
    border-radius   : 23px;
    background-color: #fff;
    padding         : 15px;
}

.lift__review_header {
    display        : flex;
    justify-content: space-between;
    align-items    : center;
}

.lift__review_header_text {
    color    : #D9D9D9;
    font-size: 16px;
}

.lift__review_header_star {
    font-size: 15px;
    color    : #B0A439;
}

.lift__review_header_star .lift__star {
    font-size   : 17px;
    position    : relative;
    top         : -2px;
    margin-right: 5px;
}

.lift__review_text {
    position     : relative;
    margin       : 7px 0 0;
    background   : rgba(229, 229, 229, 0.36);
    padding      : 9px;
    border-radius: 8px;
}

.lift__review_text_avatar {
    width   : 40px;
    position: absolute;
    right   : 15px;
}

.lift__review_text_avatar img {
    border-radius: 50%;
}

.lift__review_text_info {
    padding: 0 50px 0 0;
}

.lift__review_text_author {
    color : #D9D9D9;
    margin: 8px 0 0;
}

.css-1dgicot-container {
    width: 100%;
}

.lift__eq_table_container {

}